var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('card',{staticClass:"border-0",class:[
                _vm.shadow && 'shadow'
            ],attrs:{"type":_vm.formType,"header-classes":"bg-white pb-5","body-classes":"px-lg-5 py-lg-5"}},[[_c('div',{staticClass:"text-center text-grey-800 font-weight-bold mb-4"},[_vm._v(" "+_vm._s(_vm.registerLabel)+" ")]),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var passes = ref.passes;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return passes(_vm.signUp)}}},[_c('validation-provider',{attrs:{"mode":"lazy","name":"First Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('base-input',{staticClass:"mb-3",attrs:{"alternative":"","required":"","placeholder":"First Name","error":errors[0],"addon-left-icon":"fad fa-user"},model:{value:(_vm.fname),callback:function ($$v) {_vm.fname=$$v},expression:"fname"}})]}}],null,true)}),_c('validation-provider',{attrs:{"mode":"lazy","name":"Last Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('base-input',{staticClass:"mb-3",attrs:{"alternative":"","required":"","placeholder":"Last Name","error":errors[0],"addon-left-icon":"fas fa-user"},model:{value:(_vm.lname),callback:function ($$v) {_vm.lname=$$v},expression:"lname"}})]}}],null,true)}),_c('validation-provider',{attrs:{"mode":"lazy","name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('base-input',{staticClass:"mb-3",attrs:{"alternative":"","required":"","placeholder":"Email","error":errors[0],"addon-left-icon":"fad fa-envelope"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})]}}],null,true)}),_c('validation-provider',{attrs:{"mode":"lazy","name":"password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('base-input',{attrs:{"alternative":"","type":"password","required":"","placeholder":"Password","error":errors[0],"addon-left-icon":"fad fa-lock"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})]}}],null,true)}),_c('div',{staticClass:"text-center"},[_c('base-button',{staticClass:"my-4",attrs:{"native-type":"submit","type":"primary"}},[_vm._v("Create account")])],1)],1)]}}])})],[_c('div',{staticClass:"text-muted font-weight-bold text-center mb-3"},[_c('small',[_vm._v("OR sign in with")])]),_c('div',{staticClass:"btn-wrapper text-center"},[_c('base-button',{staticClass:"btn btn-neutral btn-facebook btn-round btn-lg",attrs:{"size":"sm","type":"neutral"},on:{"click":function($event){$event.preventDefault();return _vm.loginWithFacebook.apply(null, arguments)}}},[_c('icon',{attrs:{"name":"fab fa-facebook-square","size":"sm"}}),_vm._v(" Facebook ")],1),_c('base-button',{staticClass:"btn btn-neutral btn-google-plus btn-round btn-lg",attrs:{"size":"sm","type":"neutral"},on:{"click":function($event){$event.preventDefault();return _vm.loginWithGoogle.apply(null, arguments)}}},[_c('icon',{attrs:{"name":"fab fa-google","size":"sm"}}),_vm._v(" Google ")],1)],1)]],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }