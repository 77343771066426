<template>
  <div>

      <div v-if="view === 'start'">

        <base-button type="primary" block size="lg" @click="payNow">
          Pay Now!
        </base-button>

      </div>

      <div v-show="view === 'register'">

        <registration-form ref="regForm" redirect-to="" register-label="Get your lifetime access now!" form-type="white"></registration-form>

      </div>

      <div v-if="user.data">

        <stripe-checkout
            ref="checkoutRef"
            mode="payment"
            :pk="publishableKey"
            :line-items="lineItems"
            :success-url="successURL"
            :cancel-url="cancelURL"
            :client-reference-id="user.data.uid"
            :customer-email="user.data.email"
            @loading="v => loading = v"
        />

      </div>

      <modal
        :show.sync="modals.stripeLoading"
        gradient="default"
        modal-classes="modal-default modal-dialog-centered"
      >
        <div class="py-3 text-center">
          <i class="fad fa-spinner-third fa-4x fa-spin"></i>
          <h4 class="heading mt-4">We are redirecting you...</h4>
          <p>
            You are being redirected to your payment page.. please wait
          </p>
        </div>
      </modal>

  </div>
</template>
<script>
import firebase from "firebase/app";
import { db, functions } from "@/firebase";
import store from "@/store.js";
import { mapGetters } from "vuex";
import licenseTypes from "@/models/licenseTypes";
import Modal from "@/components/Modal.vue";
import { VBPopover } from "bootstrap-vue/esm/directives/popover/popover";
import { StripeCheckout } from '@vue-stripe/vue-stripe';

import RegistrationForm from "@/components/auth/Form";

export default {
  name: "lifetime-reflector",
  props: ["pricing"],
  components: {
    Modal,StripeCheckout,RegistrationForm
  },
  directives: {
    BPopover: VBPopover,
  },

  mounted() {
    this.$watch(
      "$refs.regForm.regStatus", (new_value, old_value) => {
        //execute your code here
        if(new_value === 'success'){
            // this.$router.replace('reflectnow')
        }
      }
    );
  },

  data() {
    this.publishableKey = 'pk_live_51Hoiu3LkK5V4hmYRfieXjPwbBGW5znDQSsE56QVzzzOIOwsqjdjOX8jGyLcND8cKh9gm6V6BbJ2my2Wz4XXyd1Sx00K2tNTvot';
    // this.publishableKey = 'pk_test_51Hoiu3LkK5V4hmYRF1xNrh2LLfo2dwRFGD445C9rgC4D0J9TD8N3bnAHJ7Gvx3ZpEPAEgiwLpRjzdQkS6nl7vLvs0076k7YVhN';
    return {
      view: 'start',
      loading: false,
      lineItems: [
        {
          price: 'price_1IZnm8LkK5V4hmYRd65wxxjC', // USD by default
          // price: 'price_1IcJrTLkK5V4hmYRxce0ASi8', // USD Testing Price
          quantity: 1,
        },
      ],
      successURL: 'https://www.myreflections.app/reflectnow',
      cancelURL: 'https://www.myreflections.app/lifetime-offer',
      // successURL: 'https://my-reflection-dev.web.app/reflectnow', // TESTING URLS
      // cancelURL: 'https://my-reflection-dev.web.app/lifetime-offer', // TESTING URLS

      gifted: false,
      requestSent: false,
      requiredLicenseName: null,
      licenseTypes: licenseTypes,
      requestText: null,
      modals: {
        stripeLoading: false,
      },
    };
  },
  computed: {
    ...mapGetters({
      // map `this.user` to `this.$store.getters.user`
      // map `this.groups` to `this.$store.getters.groups`
      user: "user",
      groups: "groups",
    }),
  },
  methods: {

    payNow(){

      this.lineItems[0].price = this.pricing.stripe_price_id;

      if(this.user.data){
        this.modals.stripeLoading = true
        this.$refs.checkoutRef.redirectToCheckout();
      }else{
        this.view = 'register'
      }

    },

    submit () {

        this.modals.stripeLoading = true
        // You will be redirected to Stripe's secure checkout page
        this.$refs.checkoutRef.redirectToCheckout();

    },

    // Receive Registration Success message from form
    receiveRegStatus(uid){

      this.modals.stripeLoading = true
      this.$refs.checkoutRef.redirectToCheckout();

    }

  },
};
</script>
<style scoped></style>
