
// firebase.js
import firebase from 'firebase/app'
import "firebase/analytics";
import { initializeApp } from 'firebase';

const firebaseConfig = {
  apiKey: "AIzaSyDxctp3Y51a2FZzsCbqTLtg-We5TlJpR4c",
  authDomain: "my-reflection-22932.firebaseapp.com",
  databaseURL: "https://my-reflection-22932.firebaseio.com",
  projectId: "my-reflection-22932",
  storageBucket: "my-reflection-22932.appspot.com",
  messagingSenderId: "248556036929",
  appId: "1:248556036929:web:511e41239bb20de68a77c0",
  measurementId: "G-QSK73TQEHX"
};

const app = initializeApp(firebaseConfig);

firebase.analytics();

export const db = app.firestore();
export const usersCollection = db.collection('users');
export const reflectionsCollection = db.collection('reflections');

// export const messaging = firebase.messaging();
export const functions = firebase.functions();