<template>

    <!-- BUY NOW! -->
    <section class="section section-lg pt-lg" :class="topMargin">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-12">
            <div class="row justify-content-center">
              <div class="col-lg-6">
                <card class="border-0" shadow shadow-large body-classes="py-5 text-center">
                  <img src="/img/images/icons8-handshake-heart.svg" class="deal-image" alt="Lifetime Commitment to your Reflection">
                  <h6 class="offer-sub-text text-uppercase text-primary">
                    Lifetime Commitment<br>to your Reflection
                  </h6>
                  <p class="description mt-3 ml-lg-4 mr-lg-4">
                    For anybody who is serious about 
                    reflection and wants to ensure 
                    that they get the most out of their
                    experiences.
                  </p>

                  <h1 class="display-1 font-weight-bold mb-0 mt-3">${{ selectedPricing.amount }}</h1>
                  <p class="small text-muted text-uppercase">({{ selectedPricing.currency }}) One Time Payment</p>

                  <div>
                    <ul class="list-group text-left">
                      <li class="list-group-item text-default small">
                        <i class="text-primary fas fa-check mr-2"></i>  Access to all future professional features, forever!
                      </li>
                      <li class="list-group-item text-default small">
                        <i class="text-primary fas fa-check mr-2"></i> Unlimited daily reflections
                      </li>
                      <li class="list-group-item text-default small">
                        <i class="text-primary fas fa-check mr-2"></i>  Keep photo journals!
                      </li>
                      <li class="list-group-item text-default small">
                        <i class="text-primary fas fa-check mr-2"></i>  Weekly reports
                      </li>
                      <li class="list-group-item text-default small">
                        <i class="text-primary fas fa-check mr-2"></i>  Reflect with up to 5 friends
                      </li>
                      <li class="list-group-item text-default small pointer">
                        <span
                          v-b-popover.hover.top="'Reflection Bot is responsible for AI and Machine Learning such as Sentiment Analysis'"
                        >
                          <i class="text-primary fas fa-check mr-2"></i>  Unlimited use of <span class="text-primary lifetime-offer"><i class="fas fa-robot"></i> Reflection Bot</span><i class="fas fa-question-circle ml-2"></i>
                        </span>
                      </li>
                      <li class="list-group-item text-default small pointer">
                        <span
                          v-b-popover.hover.top="'Reflection Automation will allow you to automatically create reflections via your calendars, task management systems or any other service that supports posting to webhooks'"
                        >
                          <i class="text-primary fas fa-check mr-2"></i>  Unrestricted Access to <span class="text-primary lifetime-offer"><i class="fas fa-plug"></i> Reflection Automation</span><i class="fas fa-question-circle ml-2"></i>
                        </span>
                        
                      </li>

                      <li class="list-group-item text-default small pointer">
                        <span
                          v-b-popover.hover.top="'Development of native apps will be dependant on the successful growth of My Reflections'"
                        >
                          <i class="text-primary fas fa-check mr-2"></i>  Access to native apps for iOS &amp; Android<i class="fas fa-question-circle ml-2"></i>
                        </span>
                      </li>
                    </ul>
                  </div>

                  <lifetime :pricing="selectedPricing" class="mt-5"/>

                </card>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

</template>

<script>
import Lifetime from "@/components/billing/Lifetime";

export default {
  name: "feature-buynow",
  props: ['topMargin'],
  components: {
    Lifetime
  },
  data() {
    return {
      australia: {
        // stripe_price_id: 'price_1IcJrTLkK5V4hmYRp6dotnv9', // TESTING
        stripe_price_id: 'price_1IZo96LkK5V4hmYRhqObYLwY',
        amount: 49,
        currency: 'AUD'
      },
      other: {
        // stripe_price_id: 'price_1IcJrTLkK5V4hmYRxce0ASi8', // TESTING
        stripe_price_id: 'price_1IZnm8LkK5V4hmYRd65wxxjC',
        amount: 39,
        currency: 'USD'
      },
      selectedPricing: {
        // stripe_price_id: 'price_1IcJrTLkK5V4hmYRxce0ASi8', // TESTING
        stripe_price_id: 'price_1IZnm8LkK5V4hmYRd65wxxjC',
        amount: 39,
        currency: 'USD'
      }
    };
  },

  mounted() {
      this.setPricing()
  },

  methods: {

    setPricing() {

        const vm = this
        const axios = require('axios');

        let url = 'https://australia-southeast1-my-reflection-22932.cloudfunctions.net/app/geochecker'
        axios.get( url )
        .then(function (response) {

          let data = response.data
          if(data.country === 'AU'){
            vm.selectedPricing = vm.australia;
          }

        });
      
    }

  }

}

</script>

<style scoped>

  .deal-image{
    width: 100px;
  }

</style>