<template>

    <div>
    
        <card :type="formType"
                header-classes="bg-white pb-5"
                body-classes="px-lg-5 py-lg-5"
                class="border-0"
                :class="[
                    shadow && 'shadow'
                ]"
        >
            <template>
                <div class="text-center text-grey-800 font-weight-bold mb-4">
                    {{ registerLabel }}
                </div>
                <ValidationObserver v-slot="{ passes }">
                    <form @submit.prevent="passes(signUp)">

                        <validation-provider mode="lazy" name="First Name" rules="required" v-slot="{ errors }">
                            <base-input alternative
                                        v-model="fname"
                                        required
                                        class="mb-3"
                                        placeholder="First Name"
                                        :error="errors[0]"
                                        addon-left-icon="fad fa-user">
                            </base-input>
                        </validation-provider>

                        <validation-provider mode="lazy" name="Last Name" rules="required" v-slot="{ errors }">
                            <base-input alternative
                                        v-model="lname"
                                        required
                                        class="mb-3"
                                        placeholder="Last Name"
                                        :error="errors[0]"
                                        addon-left-icon="fas fa-user">
                            </base-input>
                        </validation-provider>

                        <validation-provider mode="lazy" name="email" rules="required|email" v-slot="{ errors }">
                            <base-input alternative
                                        v-model="email"
                                        required
                                        class="mb-3"
                                        placeholder="Email"
                                        :error="errors[0]"
                                        addon-left-icon="fad fa-envelope">
                            </base-input>
                        </validation-provider>

                        <validation-provider mode="lazy" name="password" rules="required" v-slot="{ errors }">
                            <base-input alternative
                                        type="password"
                                        required
                                        v-model="password"
                                        placeholder="Password"
                                        :error="errors[0]"
                                        addon-left-icon="fad fa-lock">
                            </base-input>
                        </validation-provider>

                        <div class="text-center">
                            <base-button native-type="submit" type="primary" class="my-4">Create account</base-button>
                        </div>
                    </form>
                </ValidationObserver>
                
            </template>


            <template>
                <div class="text-muted font-weight-bold text-center mb-3">
                    <small>OR sign in with</small>
                </div>
                <div class="btn-wrapper text-center">

                    <base-button class="btn btn-neutral btn-facebook btn-round btn-lg" size="sm" type="neutral" @click.prevent="loginWithFacebook">
                        <icon name="fab fa-facebook-square"  size="sm"></icon>
                        Facebook
                    </base-button>

                    <base-button class="btn btn-neutral btn-google-plus btn-round btn-lg" size="sm" type="neutral" @click.prevent="loginWithGoogle">
                        <icon name="fab fa-google" size="sm"></icon>
                        Google
                    </base-button>

                </div>
            </template>

        </card>

    </div>

</template>
<script>
  import firebase from 'firebase/app'
  import { db } from '@/firebase';
  import store from '@/store.js'
//   import { mapGetters } from "vuex";

  import { ValidationProvider,ValidationObserver } from 'vee-validate';
  import { extend } from 'vee-validate';
  import { required, email } from 'vee-validate/dist/rules';

    extend('email', email);
    extend('required',required );
  
  export default {
    name: 'registration-form',
    props: ['redirectTo','registerLabel','formType','shadow'],
    components: {
        ValidationProvider,
        ValidationObserver
    },
    data() {
      return {
        fname: '',
        lname: '',
        email: '',
        password: '',
        confirm_password: '',
        regStatus: 'pending',
        uid: null
      }
    },
    methods: {

        setSuccess(){

            this.regStatus = 'success'
            if (typeof this.$parent.receiveRegStatus === "function") {

                this.$parent.receiveRegStatus(this.uid)
            }

        },
        
        signUp: function() {

            const vm = this;

            firebase.auth().createUserWithEmailAndPassword(this.email, this.password).then(
                (user) => {

                    vm.sentVerificationEmail()

                    let ourDate = new Date();
                    let timeStamp = new firebase.firestore.Timestamp.fromDate(ourDate) 

                    vm.uid = user.user.uid

                    const userMap = {
                        firstName : vm.fname.trim(),
                        lastName : vm.lname.trim(),
                        fullName : vm.fname.trim() + ' ' + vm.lname.trim(),
                        email : vm.email.trim(),
                        uid : user.user.uid,
                        token : null,
                        creationTime : timeStamp,
                        lastSignInTime : timeStamp,
                        emailVerified : user.user.emailVerified,
                        isAnonymous : user.user.isAnonymous,
                        photoURL :  user.user.photoURL,
                        providerId :  user.additionalUserInfo.providerId,
                        location : null,
                        dateOfBirth : null,
                        biography : null,
                        statusText : null,
                        tags:[],
                        reflectionStats : {
                            reflections_total: 0,
                            reflections_meeting: 0,
                            reflections_task: 0,
                            reflections_project: 0,
                            reflections_training: 0,
                            reflections_game: 0,
                            reflections_wellness: 0,
                            reflections_appointment: 0,
                            reflections_learning: 0,
                            reflections_personal: 0,
                            reflections_general: 0,
                            reflections_event: 0,
                            reflections_sleep: 0,
                            ratings_total: 0,
                            ratings_meeting: 0,
                            ratings_task: 0,
                            ratings_project: 0,
                            ratings_training: 0,
                            ratings_game: 0,
                            ratings_wellness: 0,
                            ratings_appointment: 0,
                            ratings_learning: 0,
                            ratings_personal: 0,
                            ratings_general: 0,
                            ratings_event: 0,
                            ratings_sleep: 0
                        },
                        reflectionsWeekly : [],
                        subscriptionDetails: {}
                    };

                    db.collection("users").doc(user.user.uid).set(userMap)
                    .then(function(docRef) {

                        vm.$analytics.logEvent('registered', {'source':'register'});

                        vm.$store.commit("SET_LOGGED_IN", true);
                        vm.$store.commit("SET_USER", userMap);

                        setTimeout(vm.setSuccess, 500);

                    })
                    .catch(function(error) {
                        // console.error("Error adding document: ", error);
                    });

                },
                (err) => {
                    alert('Oops. ' + err.message)
                }
            );
        },

        sentVerificationEmail(){

            var thisUser = firebase.auth().currentUser;

            thisUser.sendEmailVerification().then(function() {
                // Email sent.
            }).catch(function(error) {
                // An error happened.
            });

        },

        loginWithFacebook(){
            
            const vm = this

            var provider = new firebase.auth.FacebookAuthProvider();
            firebase.auth().signInWithPopup(provider).then(function(result) {
                
                // This gives you a Google Access Token. You can use it to access the Google API.
                if (result.credential) {
                    // This gives you a Google Access Token.
                    var token = result.credential.accessToken;
                }
                var user = result.user;
                vm.uid = user.uid
                
                // Let us confirm if the user exists or not
                var docRef = db.collection("users").doc(user.uid);
                docRef.get().then(function(doc) {
                    if (doc.exists) {

                        vm.$analytics.logEvent('login',{'provider':'facebook'});
                        vm.$store.commit("SET_LOGGED_IN", true);

                        setTimeout(vm.setSuccess, 500)
                        
                    } else {

                        let ourDate = new Date();
                        let timeStamp = new firebase.firestore.Timestamp.fromDate(ourDate) 

                        var nameSplit = user.displayName.split(' ');
                        var firstName = nameSplit[0]
                        var lastName = nameSplit[nameSplit.length-1]

                        const userMap = {
                            firstName : firstName,
                            lastName : lastName,
                            fullName : user.displayName,
                            email : user.email,
                            token: token,
                            uid : user.uid,
                            creationTime : timeStamp,
                            lastSignInTime : timeStamp,
                            emailVerified : user.emailVerified,
                            isAnonymous : user.isAnonymous,
                            photoURL :  user.photoURL,
                            providerId : 'facebook.com',
                            location : null,
                            dateOfBirth : null,
                            biography : null,
                            statusText : null,
                            tags:[],
                            reflectionStats : {
                                reflections_total: 0,
                                reflections_meeting: 0,
                                reflections_task: 0,
                                reflections_project: 0,
                                reflections_training: 0,
                                reflections_game: 0,
                                reflections_wellness: 0,
                                reflections_appointment: 0,
                                reflections_learning: 0,
                                reflections_personal: 0,
                                reflections_general: 0,
                                reflections_event: 0,
                                reflections_sleep: 0,
                                ratings_total: 0,
                                ratings_meeting: 0,
                                ratings_task: 0,
                                ratings_project: 0,
                                ratings_training: 0,
                                ratings_game: 0,
                                ratings_wellness: 0,
                                ratings_appointment: 0,
                                ratings_learning: 0,
                                ratings_personal: 0,
                                ratings_general: 0,
                                ratings_event: 0,
                                ratings_sleep: 0
                            },
                            reflectionsWeekly : [],
                            subscriptionDetails: {}
                        };

                        db.collection("users").doc(user.uid).set(userMap)
                        .then(function(docRef) {

                            vm.$analytics.logEvent('registered', {'source':'facebook'});
                            vm.$analytics.logEvent('login',{'provider':'facebook'});

                            vm.$store.commit("SET_LOGGED_IN", true);
                            vm.$store.commit("SET_USER", userMap);

                            setTimeout(vm.setSuccess, 500)

                        })
                        .catch(function(error) {
                            // console.error("Error adding document: ", error);
                        });
                        
                    }

                }).catch(function(error) {
                    // console.log("Error getting document:", error);
                });

                
            // ...
            }).catch(function(error) {
                // Handle Errors here.
                var errorCode = error.code;
                var errorMessage = error.message;
                // The email of the user's account used.
                var email = error.email;
                // The firebase.auth.AuthCredential type that was used.
                var credential = error.credential;

                vm.error_message = errorMessage
            // ...
            });

        },

        loginWithGoogle(){
            
            const vm = this

            var provider = new firebase.auth.GoogleAuthProvider();
            firebase.auth().signInWithPopup(provider).then(function(result) {
                // This gives you a Google Access Token. You can use it to access the Google API.
                var token = result.credential.accessToken;
                // The signed-in user info.
                var user = result.user;
                vm.uid = user.uid
                
                // Let us confirm if the user exists or not
                var docRef = db.collection("users").doc(user.uid);
                docRef.get().then(function(doc) {
                    if (doc.exists) {

                        vm.$analytics.logEvent('login',{'provider':'gooogle'});
                        vm.$store.commit("SET_LOGGED_IN", true);

                        setTimeout(vm.redirect, 500)
                        
                    } else {

                        let ourDate = new Date();
                        let timeStamp = new firebase.firestore.Timestamp.fromDate(ourDate) 

                        var nameSplit = user.displayName.split(' ');
                        var firstName = nameSplit[0]
                        var lastName = nameSplit[nameSplit.length-1]

                        const userMap = {
                            firstName : firstName,
                            lastName : lastName,
                            fullName : user.displayName,
                            email : user.email,
                            token: token,
                            uid : user.uid,
                            creationTime : timeStamp,
                            lastSignInTime : timeStamp,
                            emailVerified : user.emailVerified,
                            isAnonymous : user.isAnonymous,
                            photoURL :  user.photoURL,
                            providerId : 'google.com',
                            location : null,
                            dateOfBirth : null,
                            biography : null,
                            statusText : null,
                            tags:[],
                            reflectionStats : {
                                reflections_total: 0,
                                reflections_meeting: 0,
                                reflections_task: 0,
                                reflections_project: 0,
                                reflections_training: 0,
                                reflections_game: 0,
                                reflections_wellness: 0,
                                reflections_appointment: 0,
                                reflections_learning: 0,
                                reflections_personal: 0,
                                reflections_general: 0,
                                reflections_event: 0,
                                reflections_sleep: 0,
                                ratings_total: 0,
                                ratings_meeting: 0,
                                ratings_task: 0,
                                ratings_project: 0,
                                ratings_training: 0,
                                ratings_game: 0,
                                ratings_wellness: 0,
                                ratings_appointment: 0,
                                ratings_learning: 0,
                                ratings_personal: 0,
                                ratings_general: 0,
                                ratings_event: 0,
                                ratings_sleep: 0
                            },
                            reflectionsWeekly : [],
                            subscriptionDetails: {}
                        };

                        db.collection("users").doc(user.uid).set(userMap)
                        .then(function(docRef) {

                            vm.$analytics.logEvent('registered', {'source':'google'});
                            vm.$analytics.logEvent('login',{'provider':'gooogle'});

                            vm.$store.commit("SET_LOGGED_IN", true);
                            vm.$store.commit("SET_USER", userMap);

                            setTimeout(vm.setSuccess, 500)

                        })
                        .catch(function(error) {
                            // console.error("Error adding document: ", error);
                        });
                        
                    }

                }).catch(function(error) {
                    // console.log("Error getting document:", error);
                    
                });

                
            // ...
            }).catch(function(error) {
                // Handle Errors here.
                var errorCode = error.code;
                var errorMessage = error.message;
                // The email of the user's account used.
                var email = error.email;
                // The firebase.auth.AuthCredential type that was used.
                var credential = error.credential;

                vm.error_message = errorMessage
            // ...
            });

        }

    }

  }
</script>
<style>
</style>