<template>
    <!-- Reflect Now -->
    <section class="section section section-shaped my-0 overflow-hidden" :class="bg">
      <div class="container pt-sm pb-sm">

        <div class="row justify-content-center">

          <div class="col-md-8 order-md-2" v-if="view === 'textfirst'">
            <img v-lazy="image" class="img-fluid mb-3" alt="Feature image" />
          </div>
          
          <div class="col-md-4" :class="view === 'textfirst' ? 'order-md-1' : 'order-md-2'">
            <div class="pr-md-5">
              <h6 class="offer-sub-text text-uppercase" :class="type === 'dark' ? 'text-white' : 'text-default'">
                <slot name="title"></slot>
              </h6>
              <h3 class="feature-title" :class="type === 'dark' ? 'text-light' : 'text-primary'">
                <slot name="subtext"></slot>
              </h3>
              <p :class="type === 'dark' ? 'text-white' : ''">
                <slot name="contentbody"></slot>
              </p>

            </div>
          </div>

          <div class="col-md-8 order-md-1" v-if="view === 'textsecond'">
            <img v-lazy="image" class="img-fluid mb-3" :class="imageClass" alt="Feature image" />
          </div>

          <slot name="extra"></slot>

        </div>

        

      </div>
    </section>

</template>

<script>


export default {
  name: "feature",
  props: ['bg','image','imageClass','type','view'],
  data() {
    return {};
  }

}

</script>

<style scoped>

  .offer-title{
    font-size: 6.5vh;
    font-weight: 300;
  }

  .feature-title {
    font-size: 4.5vh;
    font-weight: 500;
    line-height: 1em;
    font-family: 'Caveat';
  }

  .offer-sub-text{
    font-size: 1em;
    font-weight: 300;
  }


</style>