<template>
  <div>
    <div class="container p-0">
      <div class="row">
        <div class="col">
          <div class="text-center" v-if="entryAllowed()">

            <!-- STEP 1 : Select reflectionType -->
            <div class="card-body p-0" v-if="stepCurrent === 1">
              <h4 class="text-default mb-0 mt-0 text-left" v-if="user.data">
                Hi {{ user.data.firstName}},
              </h4>
              <h4 class="text-default text-left font-weight-bold">
                What do you want to reflect on?
              </h4>
              <div class="row justify-content-center text-center">
                <div
                  class="col-4 pt-2 pb-2 m-0 text-center justify-content-center"
                  v-for="(rt, index) in reflectionTypes"
                  v-bind:key="index"
                  @click="setReflectionType(rt)"
                >
                  <div class="text-center">
                    <img
                      :alt="rt.name"
                      class="img-rt img-rt-selection pointer"
                      v-lazy="rt.image"
                    />
                  </div>
                  <div class="label-rt text-center text-default font-weight-bold pointer">
                    {{ rt.name }}
                  </div>
                </div>
              </div>
            </div>

            <!-- STEP 2 : Reflection Details -->
            <!-- "NORMAL" REFLECTION -->
            <div
              class="card-body p-0"
              v-if="stepCurrent === 2 && !reflectionType.special"
            >
              <h4 class="text-default text-left font-weight-bold">
                {{ reflectionType.labels.introduction }}
              </h4>

              <div class="row justify-content-center" v-if="showActivity">
                <div class="col-12 pt-1">
                  <base-input
                    
                    v-model="form.activityName"
                    :placeholder="reflectionType.labels.activity"
                    input-classes="text-dark"
                  >
                  </base-input>
                </div>
              </div>

              <div class="row justify-content-center">
                <div class="col-12 pb-4 text-center">

                  <div>
                    <span class="badge badge-default pointer" @click="setDate(-1)">
                      <i class="fas fa-chevron-left"></i>
                    </span>
                    <span class="badge badge-default">
                      {{ $dayjs.unix( reflectionDate.seconds ).format("ha ddd, MMM Do YYYY") }} 
                    </span>
                    <span class="badge badge-default pointer" @click="setDate(1)">
                      <i class="fas fa-chevron-right"></i>
                    </span>
                  </div>
                </div>
              </div>

              <p class="text-default font-weight-bold mb-0 mt-4">Rate it out of 10</p>
              <div class="row justify-content-md-center">
                <div class="col-12 pt-1 pb-2">
                  <input
                    v-model.number="form.rating"
                    type="range"
                    min="1"
                    max="10"
                    class="rating"
                  />
                  <small>currently rated {{ form.rating }} out of 10</small>
                </div>
              </div>

              <p
                class="text-default font-weight-bold mb-0 mt-4"
                v-if="currentUser"
              >
                Tag your activity
                <i
                  class="fad fa-info-circle"
                  v-b-popover.hover.bottom="buildTagLink()"
                ></i>
              </p>
              <div class="row justify-content-center pt-1 pb-4" v-if="currentUser">
                <div class="col-12 mt-1 mb-0 p-2 text-center">
                  <span
                    :class="getTagClass(tag)"
                    v-for="(tag, index) in filteredTags"
                    v-bind:key="index"
                    @click="setTag(tag)"
                    :title="tag.title"
                  >
                    <span v-if="tag.scope === 'group'">
                      <i class="fal fa-users-class"></i> {{ tag.label }}
                    </span>
                    <span v-else> <i class="fal fa-tag"></i> {{ tag.name }} </span>
                  </span>
                </div>

                <add-tag :reflection-type="reflectionType.name.trim()"/>

              </div>

            </div>

            <!-- JOURNAL ENTRY -->
            <div
              class="card-body p-0"
              v-if="
                stepCurrent === 2 && 
                reflectionType.special &&
                reflectionType.name === 'journal entry'
              "
            >
              <h4 class="text-default text-left font-weight-bold">
                {{ reflectionType.labels.introduction }}
              </h4>
              <div class="row justify-content-center">
                <div class="col-12 pt-3 pb-4 text-center">
                  <div>
                    <span class="badge badge-default pointer" @click="setDate(-1)">
                      <i class="fas fa-chevron-left"></i>
                    </span>
                    <span class="badge badge-default">
                      {{ $dayjs.unix( reflectionDate.seconds ).format("ha ddd, MMM Do YYYY") }} 
                    </span>
                    <span class="badge badge-default pointer" @click="setDate(1)">
                      <i class="fas fa-chevron-right"></i>
                    </span>
                  </div>
                </div>
              </div>

              <div class="input-group mb-5">
                <textarea-autosize
                  placeholder="Start writing your journal here..."
                  ref="detailsTextarea"
                  v-model="form.activityDetails"
                  :min-height="100"
                  class="form-control text-center textarea-placeholder text-dark"
                />
              </div>

              <p class="text-default font-weight-bold mb-0 mt-4">Rate it out of 10</p>
              <div class="row justify-content-md-center">
                <div class="col-12 pt-1 pb-2">
                  <input
                    v-model="form.rating"
                    type="range"
                    min="1"
                    max="10"
                    class="rating"
                  />
                  <small>currently rated {{ form.rating }} out of 10</small>
                </div>
              </div>

            </div>

            <!-- PHOTO JOURNAL / CTA -->
            <div
              class="card-body"
              v-if="
                stepCurrent === 2 && 
                reflectionType.special &&
                reflectionType.name === 'photo journal' &&
                subscription.tier === 0
              "
              @click="clearReflection"
            >
              <div v-if="user.androidDetected">
                <h5 class="card-title text-default font-weight-bold">
                  Photo journals are not currently available on Android
                  <small><br />you can get access this feature via the webapp</small>
                </h5>
              </div>
              <div v-else-if="user.data">
                <h5 class="card-title text-default font-weight-bold">
                  Photo journals are a paid feature
                  <small><br />upgrade your account now to access this feature!</small>
                </h5>

                <router-link class="nav-link nav-link-icon" to="/subscription">
                  <base-button type="default" block>Upgrade</base-button>
                </router-link>
              </div>
              <div v-else>
                <h5 class="card-title text-default font-weight-bold">
                  Photo journals are only available for registered users
                  <small><br />try another entry type!</small>
                </h5>
              </div>
            </div>

            <!-- PHOTO JOURNAL-->
            <div
              class="card-body p-0"
              v-if="
                stepCurrent === 2 && 
                reflectionType.special &&
                reflectionType.name === 'photo journal' &&
                subscription.tier > 0
              "
            >
              <h4 class="text-default text-left font-weight-bold">
                {{ reflectionType.labels.introduction }}
              </h4>
              <div class="row justify-content-center">
                <div class="col-12 pt-3 pb-4 text-center">
                  <div>
                    <span class="badge badge-default pointer" @click="setDate(-1)">
                      <i class="fas fa-chevron-left"></i>
                    </span>
                    <span class="badge badge-default">
                      {{ $dayjs.unix( reflectionDate.seconds ).format("ha ddd, MMM Do YYYY") }} 
                    </span>
                    <span class="badge badge-default pointer" @click="setDate(1)">
                      <i class="fas fa-chevron-right"></i>
                    </span>
                  </div>
                </div>
              </div>

              <div class="row justify-content-center" v-if="showActivity">
                <div class="col-12 pt-1 pb-4">
                  <base-input
                    alternative
                    v-model="form.activityName"
                    class="mb-3"
                    placeholder="Enter a title for this image"
                  >
                  </base-input>
                </div>
              </div>

              <div class="row justify-content-center">
                <div class="col-12 pt-3 pb-4 text-center" v-if="!form.imageURL">
                  <clipper-fixed
                    :src="tmpImage"
                    :border="2"
                    :rotate="tmpImageRotation"
                    :round="false"
                    ref="clipper"
                  >
                    <div slot="placeholder" class="text-center small mb-4">
                      Select your image!
                    </div>
                  </clipper-fixed>

                  <div
                    class="text-center text-default mt-2"
                    role="group"
                    aria-label="First group"
                    v-if="tmpImage && uploadingImage"
                  >
                    <i class="fad fa-spinner-third fa-spin"></i> Uploading your image
                  </div>

                  <div
                    class="text-center"
                    role="group"
                    aria-label="First group"
                    v-if="!tmpImage && !uploadingImage"
                  >
                    <clipper-upload v-model="tmpImage">
                      <base-button type="dark">Select Image</base-button>
                    </clipper-upload>
                  </div>

                  <div
                    class="btn-toolbar justify-content-between mt-2"
                    role="toolbar"
                    v-if="tmpImage && !uploadingImage"
                  >
                    <div class="btn-group">
                      <base-button type="danger" @click="closeUpload"
                        ><i class="fal fa-ban"></i
                      ></base-button>
                    </div>
                    <div class="btn-group">
                      <base-button type="secondary" @click="rotateCCW">
                        <i class="fal fa-redo fa-flip-horizontal"></i>
                      </base-button>
                      <base-button type="secondary" @click="rotateCW">
                        <i class="fal fa-redo"></i>
                      </base-button>
                    </div>
                    <div class="btn-group">
                      <base-button type="default" @click="getClipResult">
                        <i class="fal fa-check"></i>
                      </base-button>
                    </div>
                  </div>
                </div>
                <div class="col-12 pt-3 pb-4 text-center" v-else>
                  <img :src="form.imageURL" class="img-fluid" />
                </div>
              </div>

              <p class="text-default font-weight-bold mb-0 mt-4">Rate it out of 10</p>
              <div class="row justify-content-md-center">
                <div class="col-12 pt-1 pb-2">
                  <input
                    v-model="form.rating"
                    type="range"
                    min="1"
                    max="10"
                    class="rating"
                  />
                  <small>current rated {{ form.rating }} out of 10</small>
                </div>
              </div>

            </div>

            <!-- DAILY CHECKIN-->
            <div
              class="card-body p-0"
              v-if="
                stepCurrent === 2 && 
                reflectionType.special &&
                reflectionType.name === 'daily checkin'
              "
            >

              <h4 class="text-default text-left">
                Complete your <span class="font-weight-bold">daily checkin</span>
              </h4>

              <div class="row justify-content-center">
                <div class="col-12 pt-3 pb-4 text-center">
                  <div>
                    <span class="badge badge-default pointer" @click="setDate(-1)">
                      <i class="fas fa-chevron-left"></i>
                    </span>
                    <span class="badge badge-default">
                      {{ $dayjs.unix( reflectionDate.seconds ).format("ha ddd, MMM Do YYYY") }} 
                    </span>
                    <span class="badge badge-default pointer" @click="setDate(1)">
                      <i class="fas fa-chevron-right"></i>
                    </span>
                  </div>
                </div>
              </div>

              <div v-for="(qn, index) in form.dailyCheckinQuestions" v-bind:key="index">
                <p class="card-title mt-2 text-default font-weight-bold">
                  {{ qn.question }}
                </p>
                <div class="row justify-content-md-center">
                  <div class="col-12 pt-1 pb-2" v-if="qn.type === 'range'">
                    <input
                      v-model="form.dailyCheckinQuestions[index].value"
                      type="range"
                      min="1"
                      max="10"
                      class="rating"
                    />
                    <small
                      >current {{ form.dailyCheckinQuestions[index].value }} out of
                      10</small
                    >
                  </div>
                  <div class="col-12 input-group mb-3" v-else>
                    <textarea-autosize
                      placeholder="Type your answer..."
                      :ref="form.dailyCheckinQuestions[index].id"
                      v-model="form.dailyCheckinQuestions[index].value"
                      :min-height="50"
                      class="form-control  text-dark"
                    />
                  </div>
                </div>
              </div>

              <p class="text-default font-weight-bold mb-0 mt-4">
                Overall, how do you rate your day?
              </p>
              <div class="row justify-content-md-center">
                <div class="col-12 pt-1 pb-2">
                  <input
                    v-model="form.rating"
                    type="range"
                    min="1"
                    max="10"
                    class="rating"
                  />
                  <small>current rated {{ form.rating }} out of 10</small>
                </div>
              </div>

            </div>



            <!-- STEP 3 - Set Highlights -->
            <div class="card-body p-0" v-if="stepCurrent === 3">

              <h4 class="text-default text-left">
                What did you do well; the <span class="font-weight-bold">highlights?</span>
              </h4>

              <div class="row justify-content-center pt-1 pb-4">
                <div class="col-12 m-1 p-2 text-center">
                  <div class="input-group">
                    <textarea-autosize
                      placeholder="Add a highlight..."
                      ref="highlightTextarea"
                      v-model="form.tempHighlight"
                      :min-height="50"
                      class="form-control text-dark"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-default"
                        type="button"
                        id="button-addon2"
                        @click="commitHighlight"
                      >
                        <i class="fad fa-thumbs-up"></i>
                      </button>
                    </div>
                  </div>

                  <div v-if="form.highlights.length > 0" class="mt-3">
                    <h6 class="text-primary font-weight-bold">highlights</h6>
                    <ul class="list-group">
                      <li
                        class="list-group-item"
                        v-for="(highlight, index) in form.highlights"
                        v-bind:key="index"
                      >
                        <div class="float-right">
                          <i
                            class="fa fa-trash pointer"
                            @click="deleteHighlight(index)"
                          ></i>
                        </div>
                        <span style="white-space: pre-wrap" v-html="highlight"></span>
                      </li>
                    </ul>
                  </div>
                </div>

              </div>

            </div>

            <!-- STEP 4 - Set Lowlights -->
            <div class="card-body p-0" v-if="stepCurrent === 4">

              <h4 class="text-default text-left">
                What could have been better; the <span class="font-weight-bold">lowlights?</span>
              </h4>

              <div class="row justify-content-center pt-1 pb-4">
                <div class="col-12 m-1 p-2 text-center">
                  <div class="input-group">
                    <textarea-autosize
                      placeholder="Add a lowlight..."
                      ref="lowlightTextarea"
                      v-model="form.tempLowlight"
                      :min-height="50"
                      class="form-control text-dark"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-default"
                        type="button"
                        id="button-addon2"
                        @click="commitLowlight"
                      >
                        <i class="fad fa-thumbs-down"></i>
                      </button>
                    </div>
                  </div>

                  <div v-if="form.lowlights.length > 0" class="mt-3">
                    <h6 class="text-primary font-weight-bold">lowlights</h6>
                    <ul class="list-group">
                      <li
                        class="list-group-item"
                        v-for="(lowlight, index) in form.lowlights"
                        v-bind:key="index"
                      >
                        <div class="float-right">
                          <i
                            class="fa fa-trash pointer"
                            @click="deleteLowlight(index)"
                          ></i>
                        </div>
                        <span style="white-space: pre-wrap" v-html="lowlight"></span>
                      </li>
                    </ul>
                  </div>
                </div>

              </div>

            </div>


            <!-- STEP 5 - Set Adjustments -->
            <div class="card-body p-0" v-if="stepCurrent === 5">

              <h4 class="text-default text-left">
                What <span class="font-weight-bold">adjustments</span> should you make? 
              </h4>

              <div class="row justify-content-center pt-1 pb-4">
                <div class="col-12 m-1 p-2 text-center">
                  <div class="input-group">
                    <textarea-autosize
                      placeholder="Add an adjustment..."
                      ref="adjustmentTextarea"
                      v-model="form.tempAdjustment"
                      :min-height="50"
                      class="form-control text-dark"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-default"
                        type="button"
                        id="button-addon2"
                        @click="commitAdjustment"
                      >
                        <i class="fad fa-exchange-alt"></i>
                      </button>
                    </div>
                  </div>

                  <div v-if="form.adjustments.length > 0" class="mt-3">
                    <h6 class="text-primary font-weight-bold">adjustments</h6>
                    <ul class="list-group">
                      <li
                        class="list-group-item"
                        v-for="(adjustment, index) in form.adjustments"
                        v-bind:key="index"
                      >
                        <div class="float-right">
                          <i
                            class="fa fa-trash pointer"
                            @click="deleteAdjustment(index)"
                          ></i>
                        </div>
                        <span style="white-space: pre-wrap" v-html="adjustment"></span>
                      </li>
                    </ul>
                  </div>
                </div>

              </div>

            </div>

            <!-- STEP 6 - Sentiment Analysis -->
            <div class="card-body p-0" v-if="stepCurrent === 6">

              <h4 class="text-default text-left">
                Well done! We ran a <span class="font-weight-bold">sentiment analysis</span> on your reflection
              </h4>
              <p>Use this to gain insights into potential subconscious feelings related to this activity</p>
              <div class="mb-5">
                <analysis
                    ref="ourAnalysis"
                    :document="form"
                    :current-user="user.data"
                    :hide-title="true"
                ></analysis>
              </div>

            </div>

            <!-- STEP 7 - Saving -->
            <div class="card-body p-0" v-if="stepCurrent === 7">

              <h4 class="text-default text-left">
                We're saving <span class="font-weight-bold">your reflection</span>
              </h4>
              <p class="mt-5 mb-5">
                Do not forget that consistent reflection is
                the most effective way to learn from our
                actions and their outcomes!
              </p>

            </div>

            <!-- FOOTER -->
            <div class="row mt-3" v-if="stepCurrent > 1 && stepCurrent < 7">

              <div class="col-12 text-default" v-show="displayRego">
                  <registration-form
                    ref="regForm"
                    redirect-to="reflectnow"
                    register-label="Sign up to complete your reflection"
                    form-type="white"
                  ></registration-form>
              </div>

              <div class="col text-left pt-3">

                <span class="small ml-2" v-for="step in 6" v-bind:key="step">

                  <i class="fas fa-circle text-primary" v-if="stepCurrent === step"></i>
                  <i class="fas fa-circle text-default pointer" @click="stepCurrent = step" v-else-if="stepCompleted >= step"></i>
                  <i class="fas fa-circle text-secondary" v-else></i>

                </span>

              </div>
              <div class="col" v-if="stepCurrent < 7">

                  <base-button type="default" block v-if="stepCurrent === 6 && !currentUser && !displayRego" @click="displayRego = true">
                    Complete
                  </base-button>
                  <base-button type="default" block v-show="stepCurrent === 6 && currentUser" @click="createReflection()">
                    Complete
                  </base-button>

                  <div v-if="stepCurrent < 6">

                    <base-button type="default" block v-if="canProgress()" @click="completeStep()">
                      Next
                    </base-button>
                    <base-button type="default" block disabled v-else>
                      Next
                    </base-button>

                  </div>

              </div>

            </div>

          </div>

          <!-- REACHED PAY GATEWAY -->
          <div class="mt-4 text-center" v-else>
            <div class="card-body" @click="clearReflection">
              <h5 class="card-title text-default font-weight-bold">
                You have reached your daily reflection limit
              </h5>
              <p>
                You are currently on a <b>My Reflections FREE</b> plan which allows up to
                five daily reflections. If you require more reflections you can subscribe
                to the professional plan for unlimited reflections as well as the ability
                to create &amp; manage groups for your friends &amp; associates.
              </p>

              <router-link class="nav-link nav-link-icon" to="/subscription">
                <base-button type="default">Subscribe</base-button>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import Card from "@/components/Card";
// import Reflection from "@/components/reflections/Reflection";
// import Modal from "@/components/Modal.vue";
// import BaseSwitch from "@/components/BaseSwitch.vue";
import firebase from "firebase/app";
import { db } from "@/firebase";
import store from "@/store.js";
import { mapGetters } from "vuex";

import reflectionTypes from "@/models/reflectionTypes";

import { VBPopover } from "bootstrap-vue/esm/directives/popover/popover";
import RegistrationForm from "@/components/auth/Form";
import AddTag from "@/components/reflections/AddTag";

const Analysis = () => import('@/components/analysis/ReflectionAnalysis');

export default {
  name: "reflection",
  components: {
    RegistrationForm,
    AddTag,
    Analysis
  },

  directives: {
    BPopover: VBPopover,
  },

  props: ["currentUser"],

  data() {
    return {

      stepCompleted: 0,
      stepCurrent: 1,

      hideModal: false,
      today: null,
      displayRego: false,
      reflectionTypes: reflectionTypes,
      hideConfirmation: false,
      showActivity: true,
      form: {
        analysis: null,
        action: null,
        activityName: "",
        activityDetails: "",
        imageURL: "",
        rating: 5,
        highlights: [],
        lowlights: [],
        adjustments: [],
        tempHighlight: "",
        tempLowlight: "",
        tempAdjustment: "",
        tag: null,
        tagData: null,
        alertStatus: null,
        dailyCheckinQuestions: [
          {
            id: "feeltoday",
            question: "How do I feel today?",
            type: "range",
            value: 5,
          },
          {
            id: "bestthing",
            question: "What was the best thing that happened to me today?",
            type: "text",
            value: "",
          },
          {
            id: "accomplishtomorrow",
            question: "What is the most important thing I must accomplish tomorrow?",
            type: "text",
            value: "",
          },
          {
            id: "newthing",
            question: "What is one thing that I can do to improve my day tomorrow?",
            type: "text",
            value: "",
          },
        ],
      },
      modals: {
        reflectionSaved: false,
      },

      consolidatedTags: [],

      reflectionDate: null,
      reflectionAdjustment: 0,

      addtag: false,

      // used during registration!
      fname: "",
      lname: "",
      email: "",
      password: "",
      uid: null,
      redirectToDashboard: false,

      tmpImage: null,
      tmpImageRotation: 0,
      uploadingImage: false,

      // currentUser: null,
      reflectionType: null,

      // Sentiment Analysis
      sentimentSource: null,
      skippedValues: 0,
      skipTolerance: 3

    };
  },
  mounted() {
    this.setDate(0);
    this.buildTags();
    // this.checkQueue();
  },
  watch: {
    user: {
      deep: true,
      handler() {
        this.consolidatedTags = [];
        this.buildTags();
        // this.checkQueue();
      },
    },
    reflectionQueue: {
        deep: true,
        handler() {
            this.checkQueue();
        },
    },
    // ,
    // form: {
    //   deep: true,
    //   handler() {
    //     if(this.form.action === 'resetreflection'){
    //       this.clearReflection();
    //     }
    //   },
    // }
  },
  computed: {
    ...mapGetters({
      // map `this.user` to `this.$store.getters.user`
      // map `this.groups` to `this.$store.getters.groups`
      user: "user",
      groups: "groups",
      reflectionQueue: "reflectionQueue"
    }),

    filteredTags() {
      if (this.consolidatedTags) {
        return this.consolidatedTags.filter((tag) => {
          return (
            tag.status === "active" &&
            (!tag.type || tag.type === this.reflectionType.name || tag.type === "none")
          );
        });
      }

      return [];
    },

    subscription() {
      if (this.user.subscription) {
        if (this.user.subscription.status === 1) {
          return this.user.subscription;
        }
      }

      return { tier: 0, name: "free" };
    },

  },
  methods: {

    checkQueue(){
      // console.log(this.reflectionQueue.length )
      // console.log('queue checked')
      if(this.reflectionQueue.length > 0){
        this.form.activityName = this.reflectionQueue[0].title
        this.reflectionType = this.reflectionTypes.find(rt => rt.name === 'task');
        this.completeStep();
        setTimeout(this.clearReflectionQueue,2000)
      }
    },

    clearReflectionQueue(){
      this.$store.commit("REFLECTION_QUEUE_REMOVE", 0);
    },

    canProgress(){

      if(this.stepCurrent >= 2){

        if(!this.form.activityName){
          return false;
        }

        if(!this.form.rating){
          return false;
        }

        if(this.reflectionType.name === 'photo journal' && !this.form.imageURL){
          return false;
        }

      }

      return true;

    },

    completeStep(){

      this.commitHighlight();
      this.commitLowlight();
      this.commitAdjustment();

      if(this.stepCurrent >= this.stepCompleted){
        this.stepCompleted++;
      }
      this.stepCurrent++;

      this.hideModal = false;

    },

    rotateCCW() {
      if (this.tmpImageRotation === 0) {
        this.tmpImageRotation = 315;
      } else {
        this.tmpImageRotation -= 45;
      }
    },

    rotateCW() {
      if (this.tmpImageRotation >= 315) {
        this.tmpImageRotation = 0;
      } else {
        this.tmpImageRotation += 45;
      }
    },

    getClipResult: function () {
      const vm = this;

      const canvas = this.$refs.clipper.clip({ wPixel: 900 }); //call component's clip method
      canvas.toBlob(function (blob) {
        const reader = new FileReader();
        reader.addEventListener("load", function () {
          vm.tmpImage = this.result;
        });
        vm.afterComplete(blob);
        // reader.readAsDataURL(blob);
      });
    },

    async afterComplete(upload) {
      const vm = this;

      vm.uploadingImage = true;
      var imageName = this.$uuid.v4();
      try {
        //save image
        let file = upload;
        var metadata = {
          contentType: "image/png",
        };
        var storageRef = firebase.storage().ref("reflections").child(this.user.data.uid);
        var imageRef = storageRef.child(`${imageName}.png`);
        await imageRef.put(file, metadata);
        var downloadURL = await imageRef.getDownloadURL();

        vm.form.imageURL = downloadURL;
        vm.closeUpload();
      } catch (error) {
        // console.log(error);
      }
    },

    closeUpload() {
      this.uploadingImage = false;
      this.tmpImage = null;
      this.tmpImageRotation = 0;
    },

    // Receive Registration Success message from form
    receiveRegStatus(uid) {
      this.uid = uid;
      this.redirectToDashboard = true;
      this.createReflection();
    },

    buildTags() {
      const vm = this;

      if (!vm.user.data) {
        return;
      }

      let tmpPersonalTags = [];
      if (vm.user.data.tags) {
        tmpPersonalTags = JSON.parse(JSON.stringify(vm.user.data.tags));
      }
      tmpPersonalTags.forEach((tag, ti) => {
        tag.scope = "personal";
        tag.label = tag.name;
        vm.consolidatedTags.push(tag);
      });

      // Cycle through our groups
      let tmpGroups = [];
      if (vm.groups) {
        tmpGroups = JSON.parse(JSON.stringify(vm.groups));
      }

      tmpGroups.forEach((group, gi) => {
        // Add our tmpGroupTags
        var tmpGroupTags = JSON.parse(JSON.stringify(group.tags));
        tmpGroupTags.forEach((tag, t2i) => {
          tag.groupId = group.id;
          tag.title = group.groupName;
          tag.label = tag.name;
          tag.name = group.id + "#" + tag.name;
          vm.consolidatedTags.push(tag);
        });
      });
    },

    setTag(tag) {
      if (this.form.tag === tag.name) {
        this.form.tag = null;
        this.form.tagData = null;
      } else {
        this.form.tag = tag.name;
        this.form.tagData = tag;
      }
    },

    getTagClass(tag) {
      if (tag.scope === "group") {
        if (tag.name === this.form.tag) {
          return "pointer badge badge-pill badge-dark mb-2";
        } else {
          return "pointer badge badge-pill badge-light text-dark mb-2";
        }
      } else {
        if (tag.name === this.form.tag) {
          return "pointer badge badge-pill badge-default mb-2";
        } else {
          return "pointer badge badge-pill badge-light text-default mb-2";
        }
      }
    },

    getReflectionDate(datetime) {
      let myDate = new Date(datetime.seconds * 1000);
      return myDate;
    },

    setDate(days) {
      if (days === 0) {
        this.reflectionAdjustment = days;
      } else {
        this.reflectionAdjustment += days;
      }

      this.today = this.$dayjs().format("YYYY-MM-DD");
      let ourMoment = this.$dayjs().add(this.reflectionAdjustment, 'day').format();

      let ourDate = new Date(ourMoment);
      this.reflectionDate = new firebase.firestore.Timestamp.fromDate(ourDate);
    },

    setReflectionType(rt) {

      this.showActivity = true;
      this.hideModal = false;
      this.setDate(0);
      this.reflectionType = rt;
      if (this.autoActivityName(rt.name)) {
        this.form.activityName = rt.name;
        this.showActivity = false;
      }
      this.completeStep();

    },

    autoActivityName(name) {
      let autoActivityNames = ["sleep", "journal entry", "daily checkin"];
      return autoActivityNames.includes(name);
    },

    ratingSaved() {
      // We want to track public event hits (i.e. those for non-logged in users)
      if (!this.currentUser) {
        this.$analytics.logEvent("public_reflection_created");
      }
    },

    highlightsSaved(){

      var doc = db.collection("reflections").doc();
      this.form.id = doc.id;

      this.commitHighlight();
      this.commitLowlight();
      this.commitAdjustment();
      // this.buildSentimentSource();

    },

    buildTagLink() {
      let popover = {
        variant: "primary",
        content:
          'Not sure what tags are? <a href="https://www.myreflections.app/faq/reflection-tips/what-are-tags" target="_blank">click here to learn</a>',
        html: true,
      };

      return popover;
    },

    commitHighlight() {
      if (this.form.tempHighlight) {
        this.form.highlights.push(this.form.tempHighlight.trim());
        this.form.tempHighlight = "";
      }
    },

    commitLowlight() {
      if (this.form.tempLowlight) {
        this.form.lowlights.push(this.form.tempLowlight.trim());
        this.form.tempLowlight = "";
      }
    },

    commitAdjustment() {
      if (this.form.tempAdjustment) {
        this.form.adjustments.push(this.form.tempAdjustment.trim());
        this.form.tempAdjustment = "";
      }
    },

    deleteHighlight(i) {
      this.form.highlights.splice(i, 1);
    },

    deleteLowlight(i) {
      this.form.lowlights.splice(i, 1);
    },

    deleteAdjustment(i) {
      this.form.adjustments.splice(i, 1);
    },

    getActivityClass(action) {
      if (this.form.action === action) {
        return "action_active col-3 m-1 p-2 text-center";
      } else {
        return "col-3 m-1 p-2 text-center";
      }
    },

    setActivity(action) {
      this.form.action = action;
    },

    getRatingImage(rating) {
      return "img/images/values/circled-" + rating + ".svg";
    },

    clearReflection() {

      this.form = {
        analysis: null,
        action: null,
        activityName: "",
        activityDetails: "",
        imageURL: "",
        rating: 5,
        highlights: [],
        lowlights: [],
        adjustments: [],
        tempHighlight: "",
        tempLowlight: "",
        tempAdjustment: "",
        tag: null,
        tagData: null,
        alertStatus: null,
        dailyCheckinQuestions: [
          {
            id: "feeltoday",
            question: "How do I feel today?",
            type: "range",
            value: 5,
          },
          {
            id: "bestthing",
            question: "What was the best thing that happened to me today?",
            type: "text",
            value: "",
          },
          {
            id: "accomplishtomorrow",
            question: "What is the most important thing I must accomplish tomorrow?",
            type: "text",
            value: "",
          },
          {
            id: "newthing",
            question: "What is one thing that I can do to improve my day tomorrow?",
            type: "text",
            value: "",
          },
        ]
      };

      this.reflectionType = null;
      this.stepCompleted = 0;
      this.stepCurrent = 1;
      this.hideModal = true;

    },

    formatDailyCheckinQuestions(name, questions) {
      if (name !== "daily checkin") {
        return null;
      }

      questions.forEach((q, i) => {
        if (q.type === "range") {
          questions[i].value = Number(q.value);
        }
      });

      return questions;
    },

    createAlertStatus() {
      if (!this.user.data.alerts) {
        return null;
      }

      return JSON.stringify(this.user.data.alerts);
    },

    entryAllowed() {
      // console.log(
      //   this.user.subscription.status,
      //   this.user.data.reflectionToday,
      //   this.today
      // );
      if (!this.user.data) {
        return true;
      }

      if (this.user.subscription.status > 0) {
        return true;
      } else if (this.user.subscription.status === 0) {
        if (!this.user.data.reflectionToday) {
          return true;
        }

        if (
          (this.user.data.reflectionToday.date === this.today &&
            this.user.data.reflectionToday.reflections < 6) ||
          this.user.data.reflectionToday.date !== this.today
        ) {
          return true;
        }
      }

      return false;
    },

    updateAnalysis(){
      this.form.analysis = this.$refs.ourAnalysis.analysis
    },

    createReflection() {

      this.stepCurrent++;

      // Let's make sure that any unsaved lowlights/highlights are saved first
      this.commitHighlight();
      this.commitLowlight();
      this.commitAdjustment();
      this.updateAnalysis();

      const vm = this;

      let userid = vm.uid;
      if (!userid) {
        userid = vm.currentUser.uid;
      }

      let tag = vm.form.tag;
      if (!tag) {
        tag = "none";
      }

      let tagData = vm.form.tagData;
      if (!tagData) {
        tagData = null;
      }

      var docid = this.form.id
      if(!this.form.id){
        var doc = db.collection("reflections").doc();
        docid = doc.id
      }

      let reflection = {
        id: docid,
        analysis: vm.form.analysis,
        activityName: vm.form.activityName.trim(),
        activityDetails: vm.form.activityDetails.trim(),
        reflectionType: vm.reflectionType.name.trim(),
        rating: Number(vm.form.rating),
        imageURL: vm.form.imageURL,
        highlights: vm.form.highlights,
        lowlights: vm.form.lowlights,
        adjustments: vm.form.adjustments,
        creationTime: vm.reflectionDate,
        creationSeconds: vm.reflectionDate.seconds,
        lastUpdatedTime: vm.reflectionDate,
        sentimentQueued: true,
        tag: tag,
        tagData: tagData,
        uid: userid,
        dailyCheckinQuestions: vm.formatDailyCheckinQuestions(
          vm.reflectionType.name,
          vm.form.dailyCheckinQuestions
        ),
        alertStatus: vm.createAlertStatus(),
      };

      db.collection("reflections")
        .doc(docid)
        .set(reflection)
        .then(function (docRef) {
          
          reflection.id = docid;

          // Send our Analytics event
          vm.$analytics.logEvent("reflection_created", reflection);

          // Set our stats
          vm.logUserReflection(
            vm.reflectionType.name.trim(),
            tag,
            Number(vm.form.rating),
            userid
          );

          vm.clearReflection();

          vm.$toast.open({
            message: "Reflection saved!",
            type: "success",
            // all of other options may go here
          });

          if (vm.redirectToDashboard) {
            vm.$router.replace("/reflectnow");
          }
        })
        .catch(function (error) {
          // console.log(error)

          vm.$toast.open({
            message: "Something went wrong!",
            type: "error",
            duration: 10000,
            // all of other options may go here
          });
        });
    },

    logUserReflection(type, tag, rating, uid) {
      let newUser = JSON.parse(JSON.stringify(this.user.data));

      if (!newUser.reflectionToday) {
        newUser.reflectionToday = {
          date: null,
          reflections: 0,
        };
      }

      if (newUser.reflectionToday.date !== this.today) {
        newUser.reflectionToday = {
          date: this.today,
          reflections: 0,
        };
      }

      newUser.reflectionToday.reflections++;

      // db.collection("users").doc(uid).update(newUser);
      this.$store.dispatch("updateUser", newUser);
    },
  },
};
</script>

<style scoped>

.textarea-placeholder::placeholder {
  padding-top: 25px;
}

.action_active {
  border: 2px dashed #8d6c9f;
  border-radius: 25px;
}

.bg-rt {
  background: #e6dfea;
}

.img-rt {
  /* position: relative; */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 50px;
  height: 50px;
  transition: transform 0.2s;
}

.img-rt-selection:hover {
  transform: scale(1.1);
}

.img-report {
  position: relative;
  top: 0;
  left: 0;
  width: 35px;
  height: 35px;
}

.label-rt {
  font-size: 0.7em;
}

.img-hlll {
  width: 75px;
  height: 75px;
}

.img-rating {
  position: absolute;
  top: 57px;
  margin: 0 0 0 -14px;
  width: 30px;
  height: 30px;
}

/* rating */

input[type="range"] {
  -webkit-appearance: none;
  width: 100%;
  margin: 15.7px 0;
}

input[type="range"]:focus {
  outline: none;
}

input[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  height: 4.6px;
  cursor: pointer;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0), 0px 0px 1px rgba(13, 13, 13, 0);
  background: #e6dfea;
  border-radius: 2.4px;
  border: 0px solid rgba(1, 1, 1, 0);
}

input[type="range"]::-webkit-slider-thumb {
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  border: 1px solid rgba(0, 0, 0, 0);
  height: 36px;
  width: 36px;
  border-radius: 47px;
  background: #8d6c9f;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -15.7px;
}

input[type="range"]:focus::-webkit-slider-runnable-track {
  background: #f2eef4;
}

input[type="range"]::-moz-range-track {
  width: 100%;
  height: 4.6px;
  cursor: pointer;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0), 0px 0px 1px rgba(13, 13, 13, 0);
  background: #e6dfea;
  border-radius: 2.4px;
  border: 0px solid rgba(1, 1, 1, 0);
}

input[type="range"]::-moz-range-thumb {
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  border: 1px solid rgba(0, 0, 0, 0);
  height: 36px;
  width: 36px;
  border-radius: 47px;
  background: #8d6c9f;
  cursor: pointer;
}

input[type="range"]::-ms-track {
  width: 100%;
  height: 4.6px;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  color: transparent;
}

input[type="range"]::-ms-fill-lower {
  background: #dad0e0;
  border: 0px solid rgba(1, 1, 1, 0);
  border-radius: 4.8px;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0), 0px 0px 1px rgba(13, 13, 13, 0);
}

input[type="range"]::-ms-fill-upper {
  background: #e6dfea;
  border: 0px solid rgba(1, 1, 1, 0);
  border-radius: 4.8px;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0), 0px 0px 1px rgba(13, 13, 13, 0);
}

input[type="range"]::-ms-thumb {
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  border: 1px solid rgba(0, 0, 0, 0);
  height: 36px;
  width: 36px;
  border-radius: 47px;
  background: #8d6c9f;
  cursor: pointer;
  height: 4.6px;
}

input[type="range"]:focus::-ms-fill-lower {
  background: #e6dfea;
}

input[type="range"]:focus::-ms-fill-upper {
  background: #f2eef4;
}
</style>
