<template>
    <div class="col-12 p-2 text-center">
        <a role="button" class="pointer" v-if="!showField" @click="showField = true"><small>Add a tag</small></a>

        <div v-if="showField">
            <div class="input-group mb-3">
                <input type="text" class="form-control border-default" placeholder="Enter Your Tag" v-model="tempTag">
                <div class="input-group-append">                   
                    <base-button type="default" v-if="tempTag" @click="commitTag">
                        <i class="fal fa-check"></i>
                    </base-button>
                    <base-button type="default" disabled v-else>
                        <i class="fal fa-check"></i>
                    </base-button>
                </div>
                
            </div>

            <base-checkbox v-model="tempType">Only tag for the current activity type</base-checkbox>

            <!-- <div class="form-check pointer">
                <input type="checkbox" class="form-check-input" id="activityType" v-model="tempType">
                <label class="form-check-label text-default" for="activityType">Only tag for the current activity type</label>
            </div> -->
        </div>
        

    </div>
</template>

<script>

import store from "@/store.js";
import { mapGetters } from "vuex";
import _ from "lodash";

export default {
    name: "add-tag-from-reflection",
    props: ["reflectionType"],
    data() {
        return {
            showField: false,
            tempTag: null,
            tempType: false
        }
    },
    computed: {
        ...mapGetters({
            // map `this.user` to `this.$store.getters.user`
            // map `this.groups` to `this.$store.getters.groups`
            user: "user",
            groups: "groups",
        })
    },
    watch: {
        tempTag() {
            this.tempTag = this.slugify(this.tempTag);
        }
    },
    methods: {
        
        slugify(text){

            return text.toString().toLowerCase()
                .replace(/\s+/g, '-') // Replace spaces with -
                .replace(/&/g, '-and-') // Replace & with 'and'
                .replace(/[^\w\-]+/g, '') // Remove all non-word chars
                .replace(/--+/g, '-'); // Replace multiple - with single -
        },

        commitTag() {

            if (this.tempTag) {

                let newUser = _.cloneDeep(this.user.data);
                let type = null;
                if(this.tempType){
                    type = this.reflectionType;
                }
                if (!newUser.tags) {
                    newUser.tags = [];
                }

                let tag = {
                    name: this.tempTag,
                    status: "active",
                    type: type,
                    total: 0,
                    ratings: 0,
                };
                newUser.tags.push(tag);

                this.$store.dispatch("updateUser", newUser);

                this.$toast.open({
                    message: "Tag added!",
                    type: "success",
                    // all of other options may go here
                });

                this.tempTag = "";
                this.showField = false;

            }

        },

    }
}
</script>
<style scoped>


</style>