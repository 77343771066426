<template>

    <!-- What are people saying about My Reflections? -->
    <section class="section section section-shaped my-0 overflow-hidden bg-gradient-primary" v-if="testimonials.length > 0">
      <div class="container pt-sm pb-sm">

        <div class="row justify-content-center">

            <div class="col-lg-8">
                <h3 class="text-white text-center feature-title">
                  What are people saying about My Reflections?
                </h3>
            </div>

            <div class="col-lg-8 col-12">

              <VueSlickCarousel v-bind="carouselSettings">

                  <div v-for="(testimonial, index) in testimonials" v-bind:key="index" class="pt-5">

                      <card type="white" shadow no-body class="m-4 p-3">

                        <div>
                            <div class="w-100 align-items-center text-center py-2 d-md-none d-sm-block mt--75">
                              <img
                                :alt="testimonial.name + ' from ' + testimonial.position"
                                class="avatar avatar-xl bg-white border-avater"
                                v-lazy="testimonial.image"
                              />
                            </div>
                            <div class="w-100 d-flex align-items-center py-2 ">
                              <img
                                :alt="testimonial.name + ' from ' + testimonial.position"
                                class="avatar avatar-xl bg-white border-avater ml-2 mr-4 d-md-block d-none"
                                v-lazy="testimonial.image"
                              />
                              <span class="text-default">
                                {{ testimonial.testimonial}}<br>
                                <span class="text-muted small">{{ testimonial.name}}</span>
                                <span class="text-muted small mt-0" v-if="testimonial.position"> - {{ testimonial.position}}</span>
                              </span>
                            </div>

                        </div>

                      </card>

                  </div>

              </VueSlickCarousel>

            </div>

        </div>

      </div>
    </section>

</template>

<script>

import VueSlickCarousel from 'vue-slick-carousel'

import StoryblokClient from 'storyblok-js-client'

const token = '6OPOpbp82sAZOEePnmeozgtt';
let storyapi = new StoryblokClient({
  accessToken: token
});

export default {
  name: "what-people-say",
  components: {
    VueSlickCarousel
  },
  data() {
    return {
      testimonials: [],
      carouselSettings: {
        "arrows": false,
        "dots": true,
        "infinite": true,
        "autoplay": true,
        "speed": 2000,
        "autoplaySpeed": 4000
      }
    };
  },
  mounted() {
      this.getTestimonials();
  },
  methods: {

    getTestimonials(){

        const vm = this

        storyapi.get('cdn/stories/', {
            "starts_with": "testimonials/"
        })
        .then(response => {

            let tmpQuestion = response.data.stories
            tmpQuestion.forEach(record => {

                let testimonialFound = false
                let tmpTestimonials = vm.testimonials
                tmpTestimonials.forEach(t => {
                    if(t.name === record.content.name){
                        testimonialFound = true
                    }
                });

                if(!testimonialFound){
                    vm.testimonials.push(record.content)
                }

            });
            
            // console.log(response)
        }).catch(error => { 
            // console.log(error)
        })

    }

  }

}

</script>

<style scoped>

  .bg-none {
    background: transparent;
  }

  .slide-enter-active {
    -moz-transition-duration: 0.3s;
    -webkit-transition-duration: 0.3s;
    -o-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -moz-transition-timing-function: ease-in;
    -webkit-transition-timing-function: ease-in;
    -o-transition-timing-function: ease-in;
    transition-timing-function: ease-in;
  }

  .slide-leave-active {
    -moz-transition-duration: 0.3s;
    -webkit-transition-duration: 0.3s;
    -o-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
    -webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
    -o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
    transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  }

  .slide-enter-to, .slide-leave {
    max-height: 100px;
    overflow: hidden;
  }

  .slide-enter, .slide-leave-to {
    overflow: hidden;
    max-height: 0;
  }

  .avatar-xl {
    width: 100px;
    height: 100px;
  }

  .mt--75 {
    margin-top: -75px;
  }

  .ml--75 {
    margin-left: -75px;
  }

  .feature-title {
    font-size: 4.5vh;
    font-weight: 500;
    line-height: 1em;
    font-family: 'Caveat';
  }

</style>